@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');
.home_main_content {
    background-image: url("http://netgon.net/artstyles/v-card2/assets/images/triangles-top-2.svg"), url("http://netgon.net/artstyles/v-card2/assets/images/triangles-bottom-2.svg");
    background-position: left top, right bottom;
    background-repeat: no-repeat;
    /*background-size: contain;*/
    /*height: 486px;*/
}
.home_main_content .container {}
.home_main_content .container .row {}
.home_main_content .container .row .col-md-12 {}
.home_main_content .container .row .col-md-12 .card {
    margin-top: 0px;
    background: white;
    border-radius: 30px 0 30px 30px;
    box-shadow: 0 2.5rem 2.125rem rgba(134, 151, 168, 0.1);
}
.colum-card-content {}
.colum-card-content .row {}
.right-side-main-content {
    padding-top: 35px;
    padding-right: 62px;
}
/*navbar*/
.navbar-main-content {}
.navbar-main-content .container-fluid {}
.navbar-main-content .container-fluid .row {
}
.navbar-content::before {
    content: '';
    background-image: url("http://netgon.net/artstyles/v-card2/assets/images/nav-edge.svg");
    height: 100%;
    position: absolute;
    top: 1px;
    left: -3.75rem;
    width: 8.4375rem;
    z-index: 0;
}
.navbar-content {
    background: white;
    font-weight: 500;
    padding: 20px 30px;
    border-radius: 1px 30px 0 0;
    margin-left: 43px;
    margin-top: 1px;
}
.navbar-content nav {}
.navbar-content nav ul {
    display: flex;
    margin-bottom: -11px;
    flex-wrap: wrap;
}
.navbar-content nav ul li:hover a {
    color: white;
}
/*.navbar-content nav ul li:hover {*/
/*    background: #304CFD;*/
/*    padding: 5px 18px;*/
/*    border-radius: 10px;*/
/*}*/
.navbar-content nav ul li {
    list-style: none;
    margin: 0 18px;
    background: rgba(48, 76, 253, 0);
    padding: 5px 18px;
    border-radius: 10px;
}
.navbar-content nav ul li a {
    text-decoration: none;
    color: #5F6F81;
    letter-spacing: 0.5px;
    font-size: 14px;
}
.left-nav-border-content {}
/*sidebar*/
.sidebar-colum-content {}
.sidebar-colum-content .card {
    background: #F5F8F9 !important;
    border: none;
    border-radius: 30px 30px 30px 30px !important;
    padding-top: 36px;
    padding-bottom: 30px;
    box-shadow: none !important;
}
.sidebar-profile-content {}
.sidebar-profile-content img {
    width: 68%;
    border-radius: 43px;
}
.sidebar-profile-content h3 {
    margin-bottom: 1rem;
    font-family: 'Merriweather', serif;
    font-size: 1.35rem;
    color: #44566C;
    margin-top: 18px;
}
.sidebar-profile-content h3 span {
    font-weight: 800;
    font-family: 'Merriweather', serif;
    font-size: 1.35rem;
    color: #44566C;
}
.sidebar-profile-content button a {
    text-decoration: none;
    color: #44566C;
}
.sidebar-profile-content button {
    font-size: 0.7575rem;
    font-weight: 400;
    border-radius: 20px;
    padding: 0.451rem 1.3rem;
    background-color: #E9EDF0;
    color: #44566C;
    font-family: 'Poppins', sans-serif;
    border: none;
}
.sidebar-profile-content {}
.sidebar-social-icon {
    margin: 20px 0;
}
.sidebar-social-icon i:hover {
    color: #0165de;
}
.sidebar-social-icon i {
    color: #44566C;
    font-size: 14px;
    transition: 0.3s;
    margin: 0 10px;
    cursor: pointer;
}
/*sidebar-list-content*/
.sidebar-list-content {
    padding-left: 50px;
}
.sidebar-list-content nav {}
.sidebar-list-content nav ul {}
.sidebar-list-content nav ul li i {
    margin-right: 11px;
}
.sidebar-list-content nav ul li {
    list-style: none;
    color: #44566C;
    font-family: 'Poppins', sans-serif;
    font-size: 0.9275rem;
    margin: 13px 0;
}
/*.sidebar-list-content nav ul li a:hover {*/
/*    color: #0165de;*/
/*}*/
.sidebar-list-content nav ul li a {
    text-decoration: none;
    color: #44566C;
    font-family: 'Poppins', sans-serif;
    font-size: 0.9375rem;
}
.sidebar-list-content button::before {
    content: '';
    box-shadow: 0 0.25rem 2rem rgba(48, 76, 253, 0.25), 0 0.25rem 1rem rgba(48, 76, 253, 0.25);
    margin: 0 auto;
    height: 1rem;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 64%;
    z-index: -1;
}
.sidebar-list-content button a i {
    margin-right: 5px;
}
.sidebar-list-content button a {
    text-decoration: none;
    color: white;
}
.sidebar-list-content button {
    margin-top: 11px;
    /*background-color: #304CFD;*/
    border-radius: 20px;
    border: 0;
    box-shadow: 0 0.5rem 1rem rgba(48, 76, 253, 0.15), 0 0.125rem 0.25rem rgba(48, 76, 253, 0.15);
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 0.4px;
    font-weight: 400;
    line-height: inherit;
    overflow: hidden;
    padding: 0.9565rem 1.8rem;
    font-family: 'Poppins', sans-serif;
    position: relative;
    transition: .4s ease-in-out;
    white-space: nowrap;
    /*z-index: 1;*/
}
/*about-us*/
.about-us-content {}
.about-us-content h2 {
    color: #44566C;
    font-size: 23px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    margin-bottom: 39px;
}
/*.about-us-content h2::before, .about-us-content h2::after {*/
/*    content: '';*/
/*    background-color: #304CFD;*/
/*    border-radius: 0.5rem;*/
/*    height: 0.3125rem;*/
/*    position: absolute;*/
/*    bottom: 47.0075rem;*/
/*    left: 16px;*/
/*    width: 3.12rem;*/
/*    z-index: 129;*/
/*}*/
/*.about-us-content h2::after {*/
/*    background-color: #FF965D;*/
/*    width: 1rem;*/
/*}*/
.about-us-content p {
    font-family: 'Inter', sans-serif;
    margin-bottom: 1rem;
    color: #5F6F81;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.65;
}
.card-colum-content {
    margin-top: -12px;
}
.card-colum-content .row {}
.project-card-content {
}
.project-card-content .card {
    border: 4px solid #F5F8F9 !important;
    border-radius: 30px !important;
    padding: 1.5rem !important;
    position: relative;
    text-align: center;
    margin-bottom: 42px !important;
    box-shadow: none !important;
}
.project-content {
}
.project-content img {}
.project-content h3 {
    color: #44566C;
    font-size: 17px;
    margin-top: 15px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
}
.project-content p {
    font-family: 'Inter', sans-serif;
    margin-bottom: 12px;
    color: #5F6F81;
    letter-spacing: 0.4px;
    font-size: 15px;
    margin-top: 16px;
    font-weight: 400;
    line-height: 1.65;
}
/*testimonials-man-content*/
.testimonials-man-content {}
.testimonials-man-content .row {}
.testimonials-card-content {
    margin-top: 11px;
    margin-bottom: 14px;
}
.testimonials-card-content .card p {
    font-family: 'Inter', sans-serif;
    margin-bottom: 12px;
    color: #4c5966;
    letter-spacing: 0.4px;
    font-size: 15px;
    margin-top: 16px;
    font-weight: 400;
    line-height: 1.65;
}
.testimonials-card-content .card {
    background: #F5F8F9 !important;
    border: none;
    border-radius: 25px !important;
    padding: 18px 33px;
    box-shadow: none !important;
}
.testimonial-profile-content {
    display: flex;
}
.testimonial-profile-content img {
    clip-path: url(#avatar-hexagon);
    width: 93px;
    margin-top: -53px;
    margin-right: 14px;
    border-radius: 50px;
    filter: drop-shadow(0 0.25rem 0.25rem rgba(134, 151, 168, 0.1)) drop-shadow(0 0.5rem 0.5rem rgba(134, 151, 168, 0.1));
}
.testimonial-profile-content h3 {
    font-family: 'Merriweather', serif;
    font-size: 18px;
    color: #44566C;
    font-weight: 900;
}

/*client-main-content*/
.client-main-content {
    margin-top: -9px;
    margin-bottom: 39px;
}
.client-main-content .row {}
.client-company-img-content {}
.client-company-img-content img {}

/*resume*/
.eduction-main-content {}
.eduction-main-content .row {}
.eduction-heading-content {
    margin-bottom: 30px;
}
.eduction-heading-content a {
    color: #44566C;
    text-decoration: none;
    font-size: 20px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
}
.eduction-heading-content a img {
    height: 35px;
    margin-right: 13px;
    margin-bottom: 1px;
}
/*.testimonial-profile-content i {*/
/*    font-size: 39px;*/
/*    color: #304CFD;*/
/*    position: absolute;*/
/*    right: 34px !important;*/
/*    margin-top: -5px;*/
/*}*/
/*timeline_item*/
.timeline_item {
    border-left-color: #F0F0F6;
    border-left-width: 1px;
    border-left-style: solid;
    padding-bottom: 1.1875rem;
    padding-left: 1.375rem;
    position: relative;
    margin-left: 15px;
}
/*.timeline_item::before {*/
/*    content: '';*/
/*    background-color: #304CFD;*/
/*    box-shadow: 0 0 0 0.1875rem rgba(48, 76, 253, 0.25);*/
/*    border-radius: 50%;*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: -0.3125rem;*/
/*    height: 0.625rem;*/
/*    width: 0.625rem;*/
/*}*/
.timeline_item h5 {
    color: #44566C;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    line-height: 1.3;
    font-size: 18px;
}
.timeline_item span {
    color: #8697A8;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
}
.timeline_item p {
    font-family: 'Inter', sans-serif;
    color: #5d6d7e;
    font-size: 15px;
    margin-top: 5px;
    font-weight: 400;
}
/*language-content*/
.language-content {
    margin-top: -19px;
}
.language-content form {}
.language-content form label {
    font-family: 'Inter', sans-serif;
    color: #546372;
    font-size: 15px;
    margin-bottom: -3px;
    font-weight: 400;
}
.language-content form input {
    width: 54%;
    display: block;
    padding: -7px;
    height: 0px;
    margin: 12px 0;
}
/*knowledge-nav-list-content*/
.knowledge-nav-list-content {
    margin-top: -16px;
}
.knowledge-nav-list-content nav {}
.knowledge-nav-list-content nav ul {}
/*.knowledge-nav-list-content nav ul li i {*/
/*    color: #304CFD;*/
/*    font-size: 14px;*/
/*    margin-right: 9px;*/
/*}*/
.knowledge-nav-list-content nav ul li {
    list-style: none;
    font-family: 'Inter', sans-serif;
    color: #546372;
    font-size: 15px;
    margin: 7px 0;
    font-weight: 400;
}
/*skills-main-content*/
.skills-main-content {
    margin-bottom: 30px;
}
.skills-main-content .card {
    background: #F5F8F9 !important;
    border: none;
    border-radius: 25px !important;
    padding: 18px 33px;
    box-shadow: none !important;
}
.skills-main-content .card .progress {
    height: 28px;
    border-radius: 51px;
    background: rgba(68, 86, 108, 0.1);
    margin: 10px 0;
}
.skills-main-content .card .progress .progress-bar .progress-span-content {
    display: flex;
    justify-content: space-between;
}
.skills-main-content .card .progress .progress-bar .progress-span-content span {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    letter-spacing: 0.3px;
    color: white;
}
/*.skills-main-content .card .progress .progress-bar {*/
/*    width: 80%;*/
/*    background: #304CFD;*/
/*    padding: 0 18px;*/
/*    border-radius: 50px;*/
/*}*/
/*portfolio-nav-content*/
.portfolio-nav-content {}
.portfolio-nav-content nav {}
.portfolio-nav-content nav ul {
    display: flex;
}
.portfolio-nav-content nav ul li {
    list-style: none;
    padding-right: 32px;
}
/*.portfolio-nav-content nav ul li a:hover {*/
/*    color: #304CFD;*/
/*}*/
.portfolio-nav-content nav ul li a {
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    color: #8697A8;
    font-size: 17px;
}
/*portfolio-main-content*/
.portfolio-main-content {}
.portfolio-main-content .row {}
.portfolio-design-content {}
.portfolio-img-content {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;}
.portfolio-design-content img:hover {
    transform: scale3d(1.06, 1.061, 1.06);
}
.portfolio-design-content img {
    transition: transform .3s cubic-bezier(.2,0,.2,1);
    cursor: pointer;
}
.portfolio-design-content h3 {
    color: #44566C;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    line-height: 1.3;
    margin-top: 16px;
    font-size: 18px;
}
.portfolio-design-content p {
    color: #8697A8;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    margin-top: -2px;
}
/*contact-google-content*/
.contact-google-content {}
.contact-google-content .row {}
.contact-google-map-content {}
.contact-google-map-content .card {
    background: #F5F8F9 !important;
    border: none;
    border-radius: 25px !important;
    padding: 18px 33px;
    box-shadow: none !important;
}
/*contact-main-content*/
.contact-main-content {}
.contact-main-content .row {}
/*.contact-input-content:hover {*/
/*    border: 1px solid #304CFD;*/
/*}*/
.contact-input-content {
    background-color: #F5F8F9;
    border: 1px solid #F5F8F9;
    border-radius: 20px;
    box-shadow: none;
    color: #44566C;
    height: auto;
    padding: 1rem 1.375rem;
    position: relative;
    width: 100%;
}
.contact-input-content i {
    color: #8d9eb3;
    font-size: 19px;
}
.contact-input-content input::placeholder {
    color: #44566C !important;
}
.contact-input-content input {
    border: none;
    outline: none;
    margin-left: 11px;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #44566C !important;
    width: 90%;
    background: none;
}
.contact-message-input-content {
    margin-left: -12px;
    margin-top: 34px;
}
.contact-message-input-content textarea {
    outline: none;
    margin-left: 11px;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #44566C !important;
    width: 99%;
    background: #F5F8F9;
    border: 1px solid #F5F8F9;
    border-radius: 20px;
    box-shadow: none;
    resize: none;
}
/*contact-button-content*/
.contact-button-content {
    text-align: right;
}
/*.contact-button-content button {*/
/*    margin-top: 22px;*/
/*    margin-bottom: 39px;*/
/*    background-color: #304CFD;*/
/*    border-radius: 20px;*/
/*    border: 0;*/
/*    box-shadow: 0 0.5rem 1rem rgba(48, 76, 253, 0.15), 0 0.125rem 0.25rem rgba(48, 76, 253, 0.15);*/
/*    color: #fff;*/
/*    cursor: pointer;*/
/*    font-size: 14px;*/
/*    letter-spacing: 0.4px;*/
/*    font-weight: 400;*/
/*    line-height: inherit;*/
/*    overflow: hidden;*/
/*    padding: 0.9565rem 1.8rem;*/
/*    font-family: 'Poppins', sans-serif;*/
/*    position: relative;*/
/*    transition: .4s ease-in-out;*/
/*    white-space: nowrap;*/
/*    z-index: 1;*/
/*}*/
.contact-button-content button a {
    text-decoration: none;
    color: white;
}
.contact-button-content button a i {
    margin-right: 5px;
    color: white;
}