
.sidebar-list-content button {
    background-color: #f7972a;
}
.sidebar-list-content nav ul li a:hover {
    color: #f7972a;
}
.navbar-content nav ul li:hover {
    background: #f7972a;
    padding: 5px 18px;
    border-radius: 10px;
}
.testimonial-profile-content i {
    font-size: 39px;
    color: #f7972a;
    position: absolute;
    right: 34px !important;
    margin-top: -5px;
}
.timeline_item::before {
    content: '';
    background-color: #f7972a;
    box-shadow: 0 0 0 0.1875rem rgb(247 151 42 / 47%);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -0.3125rem;
    height: 0.625rem;
    width: 0.625rem;
}
.knowledge-nav-list-content nav ul li i {
    color: #f7972a;
    font-size: 14px;
    margin-right: 9px;
}
.skills-main-content .card .progress .progress-bar {
    width: 80%;
    background: #f7972a;
    padding: 0 18px;
    border-radius: 50px;
}
.portfolio-nav-content nav ul li a:hover {
    color: #f7972a;
}
.portfolio-link {
    color: #f7972a !important;
}
.contact-input-content:hover {
    border: 1px solid #f7972a;
}
.contact-button-content button {
    margin-top: 22px;
    margin-bottom: 39px;
    background-color: #f7972a;
    border-radius: 20px;
    border: 0;
    box-shadow: 0 0.5rem 1rem rgba(48, 76, 253, 0.15), 0 0.125rem 0.25rem rgba(48, 76, 253, 0.15);
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 0.4px;
    font-weight: 400;
    line-height: inherit;
    overflow: hidden;
    padding: 0.9565rem 1.8rem;
    font-family: 'Poppins', sans-serif;
    position: relative;
    transition: .4s ease-in-out;
    white-space: nowrap;
    /*z-index: 1;*/
}
.contact-message-input-content textarea:hover {
    border: 1px solid #f7972a;
}