
@media screen and (max-width: 1746px) {
    .slider_avatar_main_div h1{
        font-size: 55px;
    }
}

@media screen and (max-width: 1698px) {
    .slider_avatar_main_div h1{
        font-size: 50px;
    }
}

@media screen and (max-width: 1617px) {
    .slider_avatar_main_div h1{
        font-size: 47px;
    }
}

@media screen and (max-width: 1279px) {
    .slider_avatar_main_div h1{
        font-size: 35px;
    }
}

@media screen and (max-width: 1150px) {
    .slider_avatar_main_div img{
        height: 376px;
    }
    .slider_avatar_main_div h1{
        font-size: 32px;
    }
}

@media screen and (max-width: 1120px) {
    .slider_avatar_main_div img{
       display: none;
    }
    .typewriter_container{
        justify-content: center;
    }
    .slider_avatar_main_div{
        display: block;
        text-align: center;
        width: 100%;
    }
    .slider_avatar_main_div h1{
        font-size: 35px;
    }
}

@media screen and (max-width: 768px) {

}