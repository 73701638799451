.about_main_section{
    background: linear-gradient(194deg, #000000, #282c34c7), url('../../assets/bg.jpg') repeat;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    background-attachment: fixed;
}

@media screen and (max-width: 1390px) {

    .arter_about_section h1{
        font-size: 37px;
    }
    .about_btn_links{
        margin-top: 30px;
    }
}

@media screen and (max-width: 1311px) {
    .about_btn_links{

    }

    .arter_about_section h1 {
        font-size: 32px;
    }
    .arter_about_section a{
        padding: 7px 21px;
        font-size: 11px !important;
    }
}
@media screen and (max-width: 1135px) {
    .about_banner_image{
        margin-top: 10px;
        width: 85%;
    }
    .arter_about_section p{
        font-size: 14px;
        font-weight: 200;
    }
}
@media screen and (max-width: 1115px) {
    .about_btn_links{
        width: max-content;
    }

    .about_banner_image{
        margin-top: 10px;
        width: 100%;
        text-align: right;
    }
}
@media screen and (max-width: 1033px) {
    .about_banner_image{
        margin-top: 10px;
        width: 100%;
        text-align: right;
    }
}
@media screen and (max-width: 1032px) {
    .arter_about_section h1 {
        font-size: 39px;
    }
    .arter_about_section a {
        padding: 10px 35px;
        font-size: 11px !important;
    }
}
@media screen and (max-width: 3000px) {}