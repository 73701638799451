*{
    margin: 0px;
    padding: 0px;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #191923f7;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main_div_section{
    display: flex;
    width: 100%;
}

.side_bar_left{
    width: 300px;
    float: left;
    z-index: 12;
}

.main_bar_right{
    width: -webkit-fill-available;
    width: -moz-available;
    position: fixed;
    float: right;
    margin-left: 268px;
    margin-right: 40px;
    overflow-y: scroll;
    height: 100%;
}

.main_bar_right::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.main_bar_right {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}


.page_section_main_div{
    padding: 25px 35px;
}

.top_bar_section{
    display: none !important;
    position: fixed;
    background-color: #282c34;
    width: 100%;
    height: 60px;
    top: 0px;
    z-index: 11;
    color: white;
    box-sizing: content-box;
    box-shadow: 0px 0px 10px black;
}

.top_bar_section_div div{
    width: 100%;
    padding: 15px;
}

.top_bar_section_div div a i{
    margin-top: 8px;
}

.top_bar_section_div{
    display: flex;
    align-items: center;
    align-content:flex-start;
}

.page_title{
    font-size: 17px;
    font-weight: 600;
    color: #FFFFFF;
    font-family: "Poppins",sans-serif;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}

.resume_back_div{
    background: linear-gradient(194deg, #000000, #282c34c7), url('../assets/img.png') repeat;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    background-attachment: fixed;
}

@media screen and (max-width: 1032px) {
    .side_bar_left{
        width: 300px;
        float: left;
        box-sizing: content-box;
        box-shadow: 0px 0px 10px black;
        transition: 0.5s;
        margin-left: -300px;
    }
    .top_bar_section{
        display: block !important;
    }
    .main_div_right{
        padding-top: 60px;
    }
    .main_bar_right{
        margin-left: 0px;
        transition: 0.5s;
        margin-right: 0px;
    }
    .page_section_main_div{
        padding: 10px 20px;
    }
}





.experience_section_div{
    display: flex;

}
.experience_section_title{
    margin-bottom: 50px;
    /*border-bottom:2px dashed #ffc107;*/
    width: fit-content;
    padding-bottom: 5px;
}
.experience_section_div .end_div{
    width: 100%;
    padding-bottom: 50px;
}
.experience_section_div .start_div{
    width: 40%;
    border-right: 1px solid #ffffff40;
    text-align: right;
    padding-right: 30px !important;
}
.experience_section_div .divider:before{
    content: '';
    display: block;
    position: absolute;
    margin-top: -17px;
    width: 21px;
    height: 21px;
    position: absolute;
    margin-left: -26px;
    border-radius: 12px;
    background-color: #0099e5;
    opacity: .25;
    z-index: 0;
}
.experience_section_div .divider:after{
    content: '';
    display: block;
    position: absolute;
    margin-top: -13px;
    width: 13px;
    height: 13px;
    margin-left: -22px;
    background-color: #333;
    border-radius: 10px;
    z-index: 1;
}
.experience_section_div div{
    padding: 15px;
    padding-top: 0px !important;
}
.experience_section_div div h2{
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    font-weight: 700;
    margin-top: -17px;
}
.experience_section_div div h5{
    display: block;
    margin: 0 0 4px;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.45em;
    color: #969798;
    font-family: "Poppins", sans-serif;
}
.experience_section_div div p{
    color: #b1acac;
    margin-bottom: 5px;
    font-size: 13px;
    font-family: "Poppins",sans-serif;
}
.experience_section_div div h3{
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    font-weight: 700;
    margin-top: -17px;
}


.skills_bages{
    display: inline-block;
    border-radius: 7px;
    padding: 5px 13px;
    margin-right: 12px;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
}

.modal-body h2 {
    color: white;
}
.modal-body p {
    color: white;
}
.model_data_list {
    margin-top: 20px;
}
.model_data_list > div > h4 {
    color: white;
    font-size: 16px;
}
.model_data_list > div > p {
}



/* -----extra-style---- */

.modal-content {
    width: 82%;
}
.model_data {
    display: flex;
    justify-content: space-between;
}
.each-slide-effect {
    box-shadow: 0px 21px 0px 0px #ffc107;
}
.each-slide-effect > div {
    border-radius: 8px 8px 0px 0px;
}
.model_title h2 {
    margin-top: 37px;
    font-size: 24px;
}




@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&family=Poppins&display=swap');

.main_slider_section{
    background: linear-gradient(0deg, #282c34db, #282c343d), url("../assets/bg.jpg") repeat;
    /*background: url("https://bslthemes.com/arter/wp-content/uploads/2020/09/bg.jpg") #282c34 !important;*/
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px;
}

.main_slider_section h1{
    color: white;
    font-size: 58px;
    margin-top: 25px;
    font-weight: 800;
    font-family: "Poppins", sans-serif;
    word-wrap: break-word;
}
.main_slider_section img{
    height: 377px;
    position: relative;
    margin-bottom: -21px;
    margin-top: -60px;
}

.slider_avatar_main_div{
    display: flex;
    justify-content: space-between;
}

.slider_avatar_main_div p{
    margin-top: 20px;
    /*display: flex;*/
}
.main_slider_section a{
    margin-top: 20px;
}
.code_view{
    --widgets-spacing:20px;
    font-size: 17px;
    font-weight: 500;
    /*font-family: "Courier Prime",monospace;*/
}

.user_working_tag{
    color: white;
    --widgets-spacing:20px;
    font-size: 17px;
    font-family: "Courier Prime",monospace;
}
.typewriter_container {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: clamp(2rem, 2.8vw, 5rem);
    font-weight: bold;
    width: auto;
}
.typewriter {
    width: 21.5ch;
    white-space: nowrap;
    overflow: hidden;
    border-right: 4px solid #212121;
    animation: cursor 1s step-start infinite,
    text 5s steps(18) alternate infinite;
}

@keyframes cursor {
    0%, 100% {
        border-color: #212121;
    }
}

@keyframes text {
    0% {
        width: 0;
    }
    100% {
        width: 21.5ch;
    }
}



.btn-style{
    height: 45px;
    padding: 0 35px;
    font-size: 14px !important;
    line-height: 45px;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 600;
    justify-content: center;
    align-content: center;
    border: none;
    -webkit-transition: .4s ease-in-out;
    transition: .4s ease-in-out;
}
/*https://bslthemes.com/arter/wp-content/uploads/2020/09/face-2-min.png*/

.art-counter {
    font-weight: 600;
    font-family: "Poppins",sans-serif;
    font-size: 22px;

}
.art-counter-box{
    min-width: 60px;
}
.art-counter-plus {
    margin-left: 3px;
    font-weight: 600;
    font-family: "Poppins",sans-serif;
    font-size: 22px;
}
.art-counter-text{
    font-size: 13px;
    font-weight: 400;
    color: white;
    margin-bottom: 0;
    font-family: "Poppins",sans-serif;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}
.art-counter-frame {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
}




/*Comments*/
.art-testimonial-face {
    position: absolute;
    -o-object-fit: cover;
    object-fit: cover;
    right: 30px;
    top: -15px;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    -webkit-box-shadow: 0 3px 8px 0 rgb(15 15 20 / 20%);
    box-shadow: 0 3px 8px 0 rgb(15 15 20 / 20%);
}
.art-testimonial {
    position: relative;
    background-size: cover;
    padding: 30px;
    background: linear-gradient(159deg,#2d2d3a 0%,#2b2b35 100%);
    -webkit-box-shadow: 0 3px 8px 0 rgb(15 15 20 / 20%);
    box-shadow: 0 3px 8px 0 rgb(15 15 20 / 20%);
}
.art-testimonial .art-testimonial-footer {
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-line-pack: center;
    align-content: center;
}
.swiper-container {
    margin: 0 auto;
    list-style: none;
    padding: 0;
    z-index: 1 ;position:relative;
    overflow: hidden;
}
.art-testimonial .art-testimonial-footer .art-star-rate {
    margin: 2px;
    list-style: none;
    -webkit-box-shadow: inset 0 3px 8px 0 rgb(15 15 20 / 20%);
    box-shadow: inset 0 3px 8px 0 rgb(15 15 20 / 20%);
    padding: 5px 15px;
    font-size: 10px;
    margin-top: 5px;
    background: #20202a;
    border-radius: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.art-testimonial .art-testimonial-footer .art-star-rate  li i{
    margin: 3px;
}
.art-testimonial h5{
    color: white;
    font-size: 15px;
    font-family: "Poppins",sans-serif;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    font-weight: 600;
}
.art-testimonial .art-el-suptitle {
    color: #8c8c8e;
    font-style: italic;
    font-size: 13px;
    margin-top: 0px;
}

.art-testimonial .art-el-description p{
    --widgets-spacing: 20px;
    margin-top: 10px;
    color: #8c8c8e;
    font-size: 13px;
}

/*services*/
.art-service-icon-box .art-service-ib-content {
    padding: 30px;
}

.art-service-ib-content h5{
    color: white;
    font-size: 14px;
    font-weight: 600;
    font-family: "Poppins",sans-serif;
}

.art-service-icon-box {
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    -webkit-box-shadow: 0 3px 8px 0 rgb(15 15 20 / 20%);
    box-shadow: 0 3px 8px 0 rgb(15 15 20 / 20%);
    background: linear-gradient(159deg,#2d2d3a 0%,#2b2b35 100%);
}

.art-service-ib-content .art-link.art-w-chevron {
    padding-right: 10px;
}

.art-service-ib-content p{
    --widgets-spacing: 20px;
    margin-top: 10px;
    color: #8c8c8e;
    font-size: 13px;
}
.art-link {
    text-decoration: none !important;
    position: relative;
    text-transform: uppercase;
    font-size: 12px;
    display: inline-block;
    letter-spacing: 1.5px;
    font-weight: 600;
    -webkit-transition: .4s ease-in-out;
    transition: .4s ease-in-out;
}
.art-link.art-w-chevron:after {
    position: absolute;
    font-family: "Font Awesome 5 Free";
    content: '\f054';
    font-weight: 900;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-size: 9px;
    top: 2px;
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
    -webkit-transition: .4s ease-in-out;
    transition: .4s ease-in-out;
}
.art-link.art-w-chevron:after {
    right: 0;
}
.art-link.art-color-link:hover {
    color: #ffc107;
    text-shadow: 0 0 3px rgb(250 250 252 / 40%);
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
}

.art-card {
    background: linear-gradient(159deg,#2d2d3a 0%,#2b2b35 100%);
    -webkit-box-shadow: 0 3px 8px 0 rgb(15 15 20 / 20%);
    box-shadow: 0 3px 8px 0 rgb(15 15 20 / 20%);
    padding: 30px;
    margin-bottom: 30px;
}
.wpcf7 .screen-reader-response {
    position: absolute;
    overflow: hidden;
    clip: rect(1px,1px,1px,1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    word-wrap: normal !important;
}
.art-form-field input, .art-form-field textarea {
    height: 50px;
    width: 100%;
    background: linear-gradient(159deg,#252532 0%,#23232d 100%);
    border: none;
    color: #fafafc;
    padding-left: 65px;
    padding-right: 15px;
    -webkit-box-shadow: inset 0 1px 4px 0 rgb(15 15 20 / 10%);
    box-shadow: inset 0 1px 4px 0 rgb(15 15 20 / 10%);
    margin-bottom: 0;
    -webkit-transition: .4s ease-in-out;
    transition: .4s ease-in-out;
}
.art-contact-form label {
    text-align: center;
    color: #cacace;
    position: absolute;
    height: 50px;
    width: 50px;
    background: #20202a;
    top: 0;
    left: 0;
    padding: 0 15px;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 50px;
    letter-spacing: 1px;
    font-weight: 500;
    -webkit-transition: .4s ease-in-out;
    transition: .4s ease-in-out;
    cursor: text;
}
.wpcf7-not-valid-tip {
    color: #dc3232;
    font-size: 1em;
    font-weight: 400;
    display: block;
}
.art-contact-form .art-input:focus {
    outline: none;
}
.art-form-field {
    position: relative;
    margin-bottom: 30px;
}
.art-form-field textarea, textarea {
    padding-left: 15px;
    padding-top: 15px;
    height: 200px;
    border-left: solid 50px #20202a;
}
.art-submit-frame .art-submit {
    margin: 0;
    overflow: hidden;
    position: relative;
    z-index: 999;
}
.art-btn.art-btn-md {
    height: 45px;
    font-size: 12px;
    padding: 0 35px;
}
.art-btn {
    text-transform: uppercase;
    -webkit-box-shadow: 0 1px 4px 0 rgb(15 15 20 / 10%);
    box-shadow: 0 1px 4px 0 rgb(15 15 20 / 10%);
    color: #20202a;
    letter-spacing: 1.5px;
    font-weight: 600;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    border: none;
    -webkit-transition: .4s ease-in-out;
    transition: .4s ease-in-out;
}
.art-btn span {
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
}


.main_right_navbar{
    text-align: center;
    padding-top: 20px;
    background-color: #1a1a20;
    position: fixed;
    height: 100%;
    right: 0;
    top: 0;
    width: 50px;
    z-index: 13;
}
.art-info-bar-btn{
    color: #8c8c8e !important;
}
.main_right_navbar .navbar_title_text{
    color: #8c8c8e;
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    margin-top: 50px;
}

.nav_bar_right_side{
    background-color: #20202a;
    padding: 20px;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 250px;
    z-index: 11;
    margin-right: -250px;
    transition: 0.5s;
}
.nav_bar_right_side ul{
    margin: 0px;
    padding: 0px;
    list-style: none;
    text-align: left;
}
.nav_bar_right_side ul li{}
.nav_bar_right_side ul li a{
    display: block;
    color: #8c8c8e;
    padding: 4px 6px;
    font-family: "Poppins", sans-serif;
    text-decoration: none !important;
    margin: 4px 0px;
}

.nav_bar_right_side ul li a:hover{
    color: #fafafc;
    text-shadow: 0 0 3px rgb(250 250 252 / 40%);
}

@media screen and (max-width: 1032px) {
    .main_right_navbar{
        display: none;
    }
    .nav_bar_right_side{
        top: 60px;
    }
}


.sidebar_section{
    background: #1a1a20;
    position: fixed;
    height: 100%;
    width: 268px;
}

.side_bar_close{
    position: absolute;
    right: 19px;
    top: 17px;
    display: none;
}
.side_bar_profile_image{
    margin-top: 20px;
    height: 90px;
    width: 90px;
    border-radius: 100%;
}
.side_bar_profile_name{
    color: white;
    font-family: "Poppins",sans-serif;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 500;
}
.side_bar_profile_des{
    color: white;
    font-size: 14px;
}
.list_div_main{
    overflow-y:scroll;
    height: 70vh;
    scroll-behavior: smooth;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.list_div_main::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.list_div_main {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.profile_card_links{
    position: fixed;
    bottom: 0px;
    width: 266px;
    margin-top: 0px;
    border-radius: 0px !important;
    border: none !important;
    padding: 4px;
    background: linear-gradient(159deg,rgba(37,37,50,.98) 0%,rgba(35,35,45,.98) 100%)
}

.main_profile_div{
    background: linear-gradient(159deg,rgba(37,37,50,.98) 0%,rgba(35,35,45,.98) 100%);
}
.profile-sidebar-social{
    margin-top: 6px;
    margin-bottom: 6px;
    text-align: center;
    display: inline-block;
}
.profile-sidebar-social a{
    margin: 8px;
}
.profile-sidebar-social a .fab{
    color: white !important;
}

@media screen and (max-width: 1032px) {
    .side_bar_close{
        display: block;
    }
}



.progress_div_s{
    width: 60px;
    height: 60px;
    line-height: 150px;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;
}
.progress_div_s:after{
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid #fff;
    position: absolute;
    top: 0;
    left: 0;
}
.progress_div_s > span{
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
}
.progress_div_s .progress_div_s-left{
    left: 0;
}
.progress_div_s .progress_div_s-bar{
    width: 100%;
    height: 100%;
    background: none;
    border-width: 5px;
    border-style: solid;
    position: absolute;
    top: 0;
}
.progress_div_s .progress_div_s-left .progress_div_s-bar{
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
}
.progress_div_s .progress_div_s-right{
    right: 0;
}
.progress_div_s .progress_div_s-right .progress_div_s-bar{
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards;
}
.progress_div_s .progress_div_s-value{
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: none !important;
    font-size: 13px;
    color: #cacace !important;
    line-height: 56px;
    text-align: center;
    position: absolute;
    font-family: "Poppins",sans-serif;
    top: 5%;
    left: 5%;
}
.progress_div_s.blue .progress_div_s-bar{
    border-color: #049dff;
}
.progress_div_s.blue .progress_div_s-left .progress_div_s-bar{
    animation: loading-2 1.5s linear forwards 1.8s;
}
.progress_div_s.yellow .progress_div_s-bar{
}
.progress_div_s.yellow .progress_div_s-left .progress_div_s-bar{
    animation: loading-3 1s linear forwards 1.8s;
}
.progress_div_s.pink .progress_div_s-bar{
    border-color: #ed687c;
}
.progress_div_s.pink .progress_div_s-left .progress_div_s-bar{
    animation: loading-4 0.4s linear forwards 1.8s;
}
.progress_div_s.green .progress_div_s-bar{
    border-color: #1abc9c;
}
.progress_div_s.green .progress_div_s-left .progress_div_s-bar{
    animation: loading-5 1.2s linear forwards 1.8s;
}
@keyframes loading-1{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}
@keyframes loading-2{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(144deg);
        transform: rotate(144deg);
    }
}
@keyframes loading-3{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}
@keyframes loading-4{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(36deg);
        transform: rotate(36deg);
    }
}
@keyframes loading-5{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(126deg);
        transform: rotate(126deg);
    }
}
@media only screen and (max-width: 990px){
    .progress_div_s{ margin-bottom: 20px; }
}



@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.info_div{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #8c8c8e;
}

.info_div .main_title_info{
    color: white;
}
.info_div p{
    font-size: 13px;
    font-family: "Poppins",sans-serif;
    margin-bottom: 0px;
    color: #8c8c8e;
}

.languages_div_title h3{
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    font-family: "Poppins",sans-serif;
    color: white;
}
.languages_div{
    display: flex;
    justify-content: flex-start;
    #justify-content: space-between;
    border-bottom: 1px solid #8c8c8e;
}

/*.languages_div_experience{*/
/*    display: flex;*/
/*    justify-content: start;*/
/*    flex-wrap: wrap;*/
/*}*/
/*.languages_div_experience > div { !* Assuming each language is in a div *!*/
/*    flex: 1 1 calc(25% - 20px); !* Adjust width with margin *!*/
/*    margin: 10px; !* Add some margin for spacing *!*/
/*    box-sizing: border-box; !* Include padding and border in width *!*/
/*}*/


/*.languages_div .text_div {*/
/*    padding: 5px;*/
/*}*/

.languages_div p{
    color: white;
    margin-top: 10px;
    font-family: "Poppins",sans-serif;
    font-size: 13px;
    font-weight: 400;
}

.languages_div_experience p{
    color: white;
    margin-top: 10px;
    font-family: "Poppins",sans-serif;
    font-size: 13px;
    font-weight: 400;
}

.sidebar-knowledge-list{
    list-style: none;
    margin: 0px;
    padding: 0px;
}
.sidebar-knowledge-list li .fas{
    font-size: 10px;
    margin-right: 10px;
}
.sidebar-knowledge-list li{
    color: #8c8c8e;
    margin-bottom: 5px;
    font-size: 13px;
    font-family: "Poppins",sans-serif;
}

.coding_div{
    border-bottom: 1px solid #8c8c8e;
}
.coding-progress-bar{
    height: 6px;
    border-radius: 2px;
}

.coding_text{
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
}
.coding_text p{
    color: white;
    margin-bottom: 2px;
    font-size: 13px;
    font-family: "Poppins",sans-serif;
}
.overlay {
    display: block;
    opacity: 0;
    background: #040836;
    height: 66px;
    padding: 9px 23px;

    width: 94.5%;
    bottom: 20px;
    left: 14px;
    position: absolute;
    border-radius: 4px;
}
.product-top:hover .overlay{
    opacity: 1;
    /*transition: 1s;*/
}
.hover-effect-content {
    display: flex;
    justify-content: space-between;
}
.hover-effect-heading {}
.hover-effect-heading h3 {
    font-size: 16px;
    cursor: pointer;
    font-weight: 600;
    color: white;
    font-family: "Poppins",sans-serif;
}
.hover-effect-heading p {
    margin-bottom: 2px;
    font-size: 12px;
    color: white;
    font-family: "Poppins",sans-serif;
}
.hover-effect-button {
    margin-top: 5px;
}
.hover-effect-button a i {
    margin-top: 2px;
}
.hover-effect-button a {
    text-decoration: none;
    background: #ff014f;
    padding: 8px 10px;
    color: white;
    font-size: 15px;
    display: flex;
    border-radius: 50px;
}


.main_model{
    background-color: #1a1a20;
}

.each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
}

.each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
}


/*About page section*/
.arter_about_section{

}
.arter_about_section{
    padding: 10px 50px;
    color: white;
}
.arter_about_section h1 span{
    font-weight: 500;
}
.arter_about_section h1{
    font-size: 45px;
    margin-bottom: 20px;
    font-family: "Poppins",sans-serif;
}
.arter_about_section p{
    color: white;
    font-weight: 500;
    font-size: 17px;
    margin-top: 30px;
    font-family: "Poppins",sans-serif;
}
.arter_about_section .about_profile_image{
    border-radius: 15px;

}
.arter_about_section .about_profile_image_div{
    text-align: end;
}
.arter_about_section a{
    padding: 10px 35px;
    font-size: 14px !important;
    line-height: 45px;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 600;
    justify-content: center;
    align-content: center;
    margin-right: 15px;
    border-radius: 10px;
    text-decoration: none !important;
    transition: .4s ease-in-out;
}
.no_border_btn{
    background: none !important;
}
.about_profile_image{
    margin-top: 20px;
}
.about_banner_image{
    width: 75%;
    margin-top: -50px;
}
.about_banner_image_div{
    text-align: end;
}
.about_btn_links{
    margin-top: 80px;
}

.project_main_card{
    border-radius: 5px;
    height: 310px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
